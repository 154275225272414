<template>
    <div class="modal w-full h-full fixed top-0 left-0 z-50 flex justify-center items-center">
        <div class="modal-content flex flex-col gap-6 max-h-screen relative rounded" :style="{ width: modalWidth }">
            <div class="bg-neutral-light h-full p-5 rounded">
                <slot name="modalHeader"></slot>
            </div>
            <div class="h-full p-5">
                <slot name="modalBody"></slot>
            </div>
            <div class="h-full p-5 flex items-center justify-end gap-4">
                <slot name="modalFooter"></slot>
            </div>

            <button
                v-if="displayCloseIconButton"
                class="w-12 h-12 p-3 -bg--pink-50 rounded-full absolute -top-0 -right-0"
                @click="handleEmitClickOnIconClose"
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L6 18" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M6 6L18 18" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line
    name: 'Modal',
    emits: ['close'],
    props: {
        modalWidth: {
            type: String,
        },
        displayCloseIconButton: {
            type: Boolean,
            default: true,
        },
    },
    setup(props, { emit }) {
        const handleEmitClickOnIconClose = () => {
            console.log('click close modal');
            emit('close');
        };

        return {
            handleEmitClickOnIconClose,
        };
    },
};
</script>
<style>
.modal {
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
}
.modal-content {
    background-color: white;
}
</style>
